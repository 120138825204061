<template>
  <transition name="zoom-fade" mode="out-in">
    <div>
      <b-card-title
        v-if="state == 'form' || state == 'confirm'"
        style="width: 71%; font-size: 16px"
      >
        Расскажите подробнее о своем опыте привлечения трафика в партнерском
        маркетинге
      </b-card-title>
      <b-card-title v-else style="width: 71%; font-size: 16px">
        Для подключения необходимо подтверждение квалификации<br />
        веб-мастера
      </b-card-title>
      <div class="mt-4" v-if="state == 'confirm'">
        <div class="d-flex">
          <Lottie :options="animationData" :height="100" :width="200" />
        </div>
        <b-row>
          <b-col md="12" lg="12">
            <h3
              class="title dark animate__animated"
              data-anime="animate__fadeInUp"
              data-delay="150"
              style="text-align: center"
            >
              Анкета успешно отправлена!
            </h3>
            <!-- <p class="dark animate__animated text-overlay" data-anime="animate__fadeIn" data-delay="200" style="text-align: center; margin: 15px 30px 30px 30px">
                    В ближайшее время с вами свяжется менеджер
                  </p> -->
          </b-col>
        </b-row>
      </div>
      <div v-else-if="state == 'form'">
        <div>
          <b-card no-body class="mb-0">
            <b-card-title class="font-weight-normal">
              Заполните анкету
            </b-card-title>
            <b-card-body class="p-0">
              <!-- form -->
              <validation-observer ref="dataForm">
                <b-form
                  class=""
                  @submit.prevent="validationForm"
                >

                  <!-- username -->
                  <b-form-group label="Ваше ФИО" label-for="username">
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      rules="required"
                    >
                      <b-form-input
                        id="username"
                        v-model="form.username"
                        :state="errors.length > 0 ? false : null"
                        name="username"
                        placeholder="Иванов Иван Иванович"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                     <!-- phone -->
                  <b-form-group label="Номер телефона" label-for="phone">
                    <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required"
                    >
                      <b-form-input
                        id="phone"
                        v-model="form.phone_number"
                        :state="errors.length > 0 ? false : null"
                        name="phone"
                        :placeholder="'+7 (###) ### ##-##'"
                        v-mask="'+7 (###) ### ##-##'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                     <!-- email -->
                  <b-form-group label="Введите ваш e-mail" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        :state="errors.length > 0 ? false : null"
                        name="email"
                        placeholder="Введите ваш e-mail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                    <!-- experience -->
                  <b-form-group label="Какой у вас опыт в партнерском маркетинге?" label-for="experience">
                    <validation-provider
                      #default="{ errors }"
                      name="Experience"
                      rules="required"
                    >
                      <b-form-input
                        id="experience"
                        v-model="form.experience"
                        :state="errors.length > 0 ? false : null"
                        name="experience"
                        placeholder="Например, 3 года"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                      <!-- areas of work -->
                  <b-form-group label="Напишите ниши, в которых вы работали" label-for="areas_work">
                    <validation-provider
                      #default="{ errors }"
                      name="Areas of work"
                      rules="required"
                    >
                      <b-form-input
                        id="areas_work"
                        v-model="form.areas_work"
                        :state="errors.length > 0 ? false : null"
                        name="Areas of work"
                        placeholder="Например, финансы, туризм, страхование"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- areas of work -->
                  <b-form-group label="Ссылка на портфолио (необязательно)" label-for="Portfolio">
                    <validation-provider
                      #default="{ errors }"
                      name="Portfolio"
                      rules=""
                    >
                      <b-form-input
                        id="portfolio"
                        v-model="form.portfolio"
                        :state="errors.length > 0 ? false : null"
                        name="Portfolio"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Сопроводительное письмо (необязательно)" label-for="username">
                    <validation-provider
                      #default="{ errors }"
                      name="About"
                      rules=""
                    >
                      <b-form-textarea
                        id="about"
                        v-model="form.about"
                        placeholder=""
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit button -->
                  <b-button block variant="site-primary" type="submit">
                    Отправить анкету
                  </b-button>
                </b-form>
              </validation-observer>

              <b-card-text
                v-if="error"
                variant="danger"
                class="text-danger text-center mt-2"
              >
                {{ error }}</b-card-text
              >
            </b-card-body>
          </b-card>
        </div>
      </div>

      <div class="mt-4" v-else-if="state == 'start'">
        <b-button
          @click="state = 'training'"
          class="mb-2"
          block
          variant="site-primary"
        >
          У меня нет опыта
        </b-button>
        <b-button
          @click="state = 'form'"
          class="mb-2"
          block
          variant="site-outline-primary"
        >
          Я действующий мастер
        </b-button>
      </div>

      <div class="mt-4" v-else-if="state == 'training'">
        <p
          class="dark animate__animated text-overlay"
          data-anime="animate__fadeIn"
          data-delay="200"
          style="
            font-size: 16px;
            text-align: center;
            margin: 15px 30px 30px 30px;
          "
        >
          Пройдите обучение, чтобы получить <br />
          квалификацию мастера
        </p>
        <b-button
          href="https://academy.2work.ru"
          target="_blank"
          class="mb-2"
          block
          variant="site-primary"
        >
          Пройти обучение
        </b-button>
      </div>
    </div>
  </transition>
</template>

<script>
import User from "@/modules/user/";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as SuccessAnimeView from "@/assets/anime/success.json";

import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";

import useAppConfig from "@core/app-config/useAppConfig";

export default {
  data() {
    return {
      User,

      form: {
        username: "",
        phone_number: "",
        about: "",
        experience: "",
        areas_work: "",
        portfolio: "",
        email: ""
      },

      state: "start",
      error: null,

      skin: useAppConfig().skin,

      animationData: {
        animationData: SuccessAnimeView.default,
        loop: false,
      },
      is_loading: false,

      // validation rules
      required,
      email,
    };
  },
  methods: {
    closeModal() {
      this.$root.$emit("closeModal");
    },

    validationForm() {
      this.error = null;

      this.$refs.dataForm.validate().then((success) => {
        if (success) {
          this.$request
            .post("utils.saveWorksheet", {
              form: this.form
            })
            .then((result) => {
          		this.state = 'confirm';
            })
            .catch((err) => {
              this.error = err.message;
            });
        }
      });
    },
  },
  computed: {},
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  watch: {},
  mounted() {
    this.selectMessanger(this.selected, true);

    // const userLocale = Intl.DateTimeFormat().resolvedOptions().locale.toUpperCase();
    // this.country = this.countries.find( country => country.iso === userLocale );
    // if (typeof this.country !== 'object' && !this.country.hasOwnProperty('iso'))  this.country = this.countries.find( country => country.iso === 'RU' );
    this.country = this.countries.find((country) => country.iso === "RU");
  },
};
</script>

<style lang="scss">
#connect-modal {
  .btn-site-primary {
    padding: 17px 32px 19px;
    font-size: 16px;
    color: #000000 !important;
    background-color: #4be0aa !important;
    border-radius: 12px;
  }

  .btn-site-outline-primary {
    color: #4be0aa !important;
    border-color: #4be0aa;
    padding: 17px 32px 19px;
    font-size: 16px;
    border-radius: 12px;
  }

  p,
  h5,
  h4 {
    font-family: "SuisseIntl" !important;
  }
}

body.modal-open {
  overflow-y: hidden !important;
}
.p-f {
  padding: 0.438rem 1rem !important;
}

.l-border0 {
  border-radius: 0px 100px 100px 0px !important;
}

.l-border100 {
  border-radius: 100px;
}
</style>
