var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"zoom-fade","mode":"out-in"}},[_c('div',[(_vm.state == 'form' || _vm.state == 'confirm')?_c('b-card-title',{staticStyle:{"width":"71%","font-size":"16px"}},[_vm._v(" Расскажите подробнее о своем опыте привлечения трафика в партнерском маркетинге ")]):_c('b-card-title',{staticStyle:{"width":"71%","font-size":"16px"}},[_vm._v(" Для подключения необходимо подтверждение квалификации"),_c('br'),_vm._v(" веб-мастера ")]),(_vm.state == 'confirm')?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex"},[_c('Lottie',{attrs:{"options":_vm.animationData,"height":100,"width":200}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('h3',{staticClass:"title dark animate__animated",staticStyle:{"text-align":"center"},attrs:{"data-anime":"animate__fadeInUp","data-delay":"150"}},[_vm._v(" Анкета успешно отправлена! ")])])],1)],1):(_vm.state == 'form')?_c('div',[_c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"font-weight-normal"},[_vm._v(" Заполните анкету ")]),_c('b-card-body',{staticClass:"p-0"},[_c('validation-observer',{ref:"dataForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label":"Ваше ФИО","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"name":"username","placeholder":"Иванов Иван Иванович"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Номер телефона","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ### ##-##'),expression:"'+7 (###) ### ##-##'"}],attrs:{"id":"phone","state":errors.length > 0 ? false : null,"name":"phone","placeholder":'+7 (###) ### ##-##'},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Введите ваш e-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":"Введите ваш e-mail"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Какой у вас опыт в партнерском маркетинге?","label-for":"experience"}},[_c('validation-provider',{attrs:{"name":"Experience","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"experience","state":errors.length > 0 ? false : null,"name":"experience","placeholder":"Например, 3 года"},model:{value:(_vm.form.experience),callback:function ($$v) {_vm.$set(_vm.form, "experience", $$v)},expression:"form.experience"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Напишите ниши, в которых вы работали","label-for":"areas_work"}},[_c('validation-provider',{attrs:{"name":"Areas of work","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"areas_work","state":errors.length > 0 ? false : null,"name":"Areas of work","placeholder":"Например, финансы, туризм, страхование"},model:{value:(_vm.form.areas_work),callback:function ($$v) {_vm.$set(_vm.form, "areas_work", $$v)},expression:"form.areas_work"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Ссылка на портфолио (необязательно)","label-for":"Portfolio"}},[_c('validation-provider',{attrs:{"name":"Portfolio","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"portfolio","state":errors.length > 0 ? false : null,"name":"Portfolio","placeholder":""},model:{value:(_vm.form.portfolio),callback:function ($$v) {_vm.$set(_vm.form, "portfolio", $$v)},expression:"form.portfolio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Сопроводительное письмо (необязательно)","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"About","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"about","placeholder":"","rows":"3","max-rows":"6"},model:{value:(_vm.form.about),callback:function ($$v) {_vm.$set(_vm.form, "about", $$v)},expression:"form.about"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"block":"","variant":"site-primary","type":"submit"}},[_vm._v(" Отправить анкету ")])],1)],1),(_vm.error)?_c('b-card-text',{staticClass:"text-danger text-center mt-2",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.error))]):_vm._e()],1)],1)],1)]):(_vm.state == 'start')?_c('div',{staticClass:"mt-4"},[_c('b-button',{staticClass:"mb-2",attrs:{"block":"","variant":"site-primary"},on:{"click":function($event){_vm.state = 'training'}}},[_vm._v(" У меня нет опыта ")]),_c('b-button',{staticClass:"mb-2",attrs:{"block":"","variant":"site-outline-primary"},on:{"click":function($event){_vm.state = 'form'}}},[_vm._v(" Я действующий мастер ")])],1):(_vm.state == 'training')?_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"dark animate__animated text-overlay",staticStyle:{"font-size":"16px","text-align":"center","margin":"15px 30px 30px 30px"},attrs:{"data-anime":"animate__fadeIn","data-delay":"200"}},[_vm._v(" Пройдите обучение, чтобы получить "),_c('br'),_vm._v(" квалификацию мастера ")]),_c('b-button',{staticClass:"mb-2",attrs:{"href":"https://academy.2work.ru","target":"_blank","block":"","variant":"site-primary"}},[_vm._v(" Пройти обучение ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }